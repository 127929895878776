import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import Arrow from "../images/graphic-arrow-btn.inline.svg";

interface MoreInfoButtonProps {
  labelText: string;
  destination: string;
  className?: string;
}

const MoreInfoButton: React.FC<MoreInfoButtonProps> = ({
  labelText,
  destination,
  className,
}) => (
  <Link
    to={destination}
    className={classNames("btn btn--outlined btn--large btn--arrow", className)}
    aria-label={labelText}
  >
    {labelText}{" "}
    <span className="btn__arrow">
      {" "}
      <Arrow alt="" />
    </span>
  </Link>
);

export default MoreInfoButton;
