/// <reference lib="dom" />
import * as React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql, Link } from "gatsby";
import Layout from "../components/Layout";

import Hero from "../components/Hero";
import Masthead from "../components/Masthead";
import MoreInfoButton from "../components/MoreInfoButton";

import BragBubble from "../components/BragBubble";
import PeopleList from "../components/PeopleList";
import PersonCard from "../components/PersonCard";
import HeadingAnchor from "../components/HeadingAnchor";
import TimelineGraph from "../components/TimelineGraph";

import CompensationImage from "../images/graphic-compensation.svg";
import DedicatedTeamImage from "../images/graphic-other.svg";
import CapitalImage from "../images/graphic-ownership.svg";

import "../styles/studio.scss";

// markup
const StudioPage: React.FC<TeamProps> = ({
  data: {
    site: {
      siteMetadata: { siteUrl },
    },
    contentJson: { entrepreneurs },
  },
  location: { pathname },
}) => {
  const cohorts = {
    members: entrepreneurs,
    title: "Entrepreneurs", // Not used
    id: "studio-team",
    className: "team__studio",
  };

  const pageTitle = "ideas42 Ventures Studio - Information";

  return (
    <>
      <Layout
        footerChildren={null}
        includeYellowOnFooter={false}
        className="studio"
      >
        <Helmet htmlAttributes={{ lang: "en" }}>
          <meta charSet="utf-8" />
          <title>{pageTitle}</title>
          <meta property="og:url" content={siteUrl + pathname} />
          <meta property="og:title" content={pageTitle} />
          <meta name="twitter:title" content={pageTitle} />
        </Helmet>
        <Masthead>
          <Hero
            tag="The studio"
            title="Build with us"
            titleClass="g-max-3"
            body="We recruit talented individuals with direct, lived experiences in our focus areas and we work alongside them for 18 months in an intense research, behavioral design, prototype, sell, deployment, and fundraising process. At the end of 18 months, we expect each of our entrepreneurs to have a product in-market making a difference in the world, with capital to continue to grow far beyond the Venture Studio."
            bodyClass="hero__body--home g-max-3"
          />
          <div className="masthead__graphic--studio" />
        </Masthead>
        <section className="standard-restricted-width g-max-4 what-we-provide">
          <div className="what-we-provide__content g-max-4">
            <h2>What we provide</h2>
            <p className="font-size-3">
              Starting a business is risky, and for many individuals without a
              personal financial safety net, or access to a network of
              individuals with significant capital to fund their venture, the
              risk is simply too high. At the ideas42 Venture Studio we can’t
              guarantee your success, but we can reduce the risk by providing
              you with the support you and your business need to grow.
            </p>
          </div>
          <ul className="horizontal-list what-we-provide__list">
            <li className="list__item">
              <img className="list__image" src={CompensationImage} alt="" />
              <div className="list__header">Compensation</div>
              <small className="list-content">
                $80k salary for 18 months, healthcare, home office setup, and
                40% equity in business built
              </small>
            </li>
            <li className="list__item">
              <img className="list__image" src={DedicatedTeamImage} alt="" />
              <div className="list__header">Dedicated team</div>
              <small className="list-content">
                Full-time in house team of software engineers, product, business
                and behavioral science designers
              </small>
            </li>
            <li className="list__item">
              <img className="list__image" src={CapitalImage} alt="" />
              <div className="list__header">Business Capital</div>
              <small className="list-content">
                Access to roughly $120k to spend for sales, marketing, legal and
                additional tech support
              </small>
            </li>
          </ul>
          <MoreInfoButton
            labelText="See more details"
            destination="/studio/what-we-provide"
            className="what-we-provide__button"
          />
        </section>
        <section className="standard-restricted-width g-max-4 applications">
          <HeadingAnchor
            level="2"
            id="applications"
            text="Applications are now closed"
          />
          <p className="g-max-3 font-size-3">
            Applications for our 2022-2023 cohort on the{" "}
            <Link to="/studio/focus-area">Excess Costs of Poverty</Link> are now
            closed. Sign up for our <a href="#footer">mailing list</a> to be
            notified when applications open for our next cohort.
          </p>
          <TimelineGraph />
        </section>
        <section className="current-entrepreneurs standard-restricted-width">
          <HeadingAnchor
            level="2"
            id="current-entrepreneurs"
            className="current-entrepreneurs__header centered"
            text="Our entrepreneurs"
          />
          <BragBubble
            url="https://www.fastcompany.com/90656929/these-4-entrepreneurs-are-building-financial-products-that-make-it-less-expensive-to-be-poor"
            className="brag-bubble--margin-bottom"
            openNewTab
          >
            Our first cohort was featured in Fast Company, read more about their
            experience.
          </BragBubble>
          <HeadingAnchor
            level="3"
            id="current-entrepreneurs"
            className="current-entrepreneurs__header centered"
            small="2022 to 2023"
            text="Cohort Two"
          />
          <PeopleList
            className="people-list people-list--centered"
            DisplayCard={PersonCard}
            team={cohorts}
            filterFunc={(person: Person): boolean =>
              person.cohort === "2022-2023"
            }
          />
          <HeadingAnchor
            level="3"
            id="current-entrepreneurs"
            className="current-entrepreneurs__header centered"
            small="2021 to 2022"
            text="Cohort One"
          />
          <PeopleList
            className="people-list people-list--centered"
            DisplayCard={PersonCard}
            team={cohorts}
            filterFunc={(person: Person): boolean =>
              person.cohort === "2021-2022"
            }
          />
        </section>
      </Layout>
    </>
  );
};

export default (props: TeamProps): React.ReactNode => (
  <StaticQuery
    query={graphql`
      query StudioPageQuery {
        site {
          siteMetadata {
            siteUrl
          }
        }
        contentJson {
          entrepreneurs {
            img
            name
            linkedin
            role
            email
            cohort
            description
          }
        }
      }
    `}
    render={(data) => <StudioPage data={data} {...props} />}
  />
);
