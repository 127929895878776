import React from "react";

import "./TimelineGraph.scss";

const TimelineGraph: React.FC = () => (
  <figure className="timeline">
    <div className="timeline__contents">
      <div className="timeline__months">
        <time dateTime="2021-09" className="timeline__month">
          <strong>Sep 2021</strong>
        </time>
        <time dateTime="2021-10" className="timeline__month">
          <strong>Oct</strong>
        </time>
        <time dateTime="2021-11" className="timeline__month">
          <strong>Nov</strong>
        </time>
        <time dateTime="2021-12" className="timeline__month">
          <strong>Dec</strong>
        </time>
        <time dateTime="2022-01" className="timeline__month">
          <strong>Jan 2022</strong>
        </time>
        <time dateTime="2022-02" className="timeline__month">
          <strong>Feb</strong>
        </time>
      </div>
      <div className="timeline__dates">
        <time dateTime="2021-09-13" className="timeline__date">
          13th
        </time>
        <time dateTime="2021-10-10" className="timeline__date date--session">
          10th
        </time>
        <time dateTime="2021-10-15" className="timeline__date date--session">
          15th
        </time>
        <time dateTime="2021-10-10" className="timeline__date">
          10th
        </time>

        <time dateTime="2021-10-29" className="timeline__date">
          29th
        </time>
        <time dateTime="2021-11-15" className="timeline__date">
          15th
        </time>

        <time dateTime="2021-12-06" className="timeline__date">
          6th
        </time>
        <time dateTime="2021-12-15" className="timeline__date">
          15th
        </time>

        <time dateTime="2022-01-03" className="timeline__date">
          3rd
        </time>
        <time dateTime="2022-01-07" className="timeline__date">
          7th
        </time>

        <time dateTime="2022-01-14" className="timeline__date" />

        <time dateTime="2022-02-22" className="timeline__date" />

        <time dateTime="2022-02-23" className="timeline__date" />
      </div>

      <div className="timeline__ranges" aria-hidden="true">
        <span className="timeline__range" />
        <span className="timeline__range" />
        <span className="timeline__range" />
        <span className="timeline__range" />
        <span className="timeline__marker" />
        <span className="timeline__marker" />
      </div>

      <ul className="timeline__events">
        <li className="timeline__event">
          <strong>Applications</strong>
          Sep 13 - Oct 10
        </li>

        <li className="timeline__event">
          <strong>Case Studies</strong>
          Oct 29 - Nov 15
        </li>

        <li className="timeline__event">
          <strong>Interviews round 1</strong>
          Dec 6 - Dec 15
        </li>

        <li className="timeline__event">
          <strong>Interviews round 2</strong>
          Jan 3 - Jan 7
        </li>

        <li className="timeline__event">
          <strong>Offers</strong>
          Late Jan
        </li>
        <li className="timeline__event">
          <strong>Cohort Start</strong>
          Mid Feb
        </li>
      </ul>
    </div>
  </figure>
);

export default TimelineGraph;
