import * as React from "react";
import classNames from "classnames";
import Arrow from "../images/graphic-arrow-btn.inline.svg";

import "./BragBubble.scss";

type BragBubbleProps = {
  url: string;
  children: React.ReactNode;
  className?: string;
  openNewTab?: boolean; // or new window, depending on browser
};

const BragBubble: React.FC<BragBubbleProps> = ({
  url,
  children,
  className,
  openNewTab = false,
}) => (
  <div className={classNames("brag-bubble", className)} role="alert">
    <a
      className="brag-bubble__link btn--arrow"
      target={openNewTab ? "_blank" : "_self"}
      href={url}
      rel="noreferrer"
    >
      <div className="brag-bubble__text">{children}</div>
      <div className="btn__arrow">
        <Arrow />
      </div>
    </a>
  </div>
);

export default BragBubble;
